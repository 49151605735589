/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      id
      contentType
      title
      slug
      content
      tags
      metadata
      authorId
      author {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      featuredImage
      images
      created
      updated
    }
  }
`;
export const updateContent = /* GraphQL */ `
  mutation UpdateContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      id
      contentType
      title
      slug
      content
      tags
      metadata
      authorId
      author {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      featuredImage
      images
      created
      updated
    }
  }
`;
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent(
    $input: DeleteContentInput!
    $condition: ModelContentConditionInput
  ) {
    deleteContent(input: $input, condition: $condition) {
      id
      contentType
      title
      slug
      content
      tags
      metadata
      authorId
      author {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      featuredImage
      images
      created
      updated
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      name
      avatar
      duelRating
      created
      updated
      owner
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      name
      avatar
      duelRating
      created
      updated
      owner
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      name
      avatar
      duelRating
      created
      updated
      owner
    }
  }
`;
export const createMatch = /* GraphQL */ `
  mutation CreateMatch(
    $input: CreateMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    createMatch(input: $input, condition: $condition) {
      id
      playerOneId
      playerOne {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerOneScore
      playerTwoId
      playerTwo {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerTwoScore
      verified
      processed
      created
      updated
      owner
    }
  }
`;
export const updateMatch = /* GraphQL */ `
  mutation UpdateMatch(
    $input: UpdateMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    updateMatch(input: $input, condition: $condition) {
      id
      playerOneId
      playerOne {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerOneScore
      playerTwoId
      playerTwo {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerTwoScore
      verified
      processed
      created
      updated
      owner
    }
  }
`;
export const deleteMatch = /* GraphQL */ `
  mutation DeleteMatch(
    $input: DeleteMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    deleteMatch(input: $input, condition: $condition) {
      id
      playerOneId
      playerOne {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerOneScore
      playerTwoId
      playerTwo {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerTwoScore
      verified
      processed
      created
      updated
      owner
    }
  }
`;
export const createRatingChangeLog = /* GraphQL */ `
  mutation CreateRatingChangeLog(
    $input: CreateRatingChangeLogInput!
    $condition: ModelRatingChangeLogConditionInput
  ) {
    createRatingChangeLog(input: $input, condition: $condition) {
      id
      matchId
      match {
        id
        playerOneId
        playerOne {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerOneScore
        playerTwoId
        playerTwo {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerTwoScore
        verified
        processed
        created
        updated
        owner
      }
      playerOneId
      playerOne {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerOneInitRating
      playerOneNewRating
      playerTwoId
      playerTwo {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerTwoInitRating
      playerTwoNewRating
      created
      updated
    }
  }
`;
export const updateRatingChangeLog = /* GraphQL */ `
  mutation UpdateRatingChangeLog(
    $input: UpdateRatingChangeLogInput!
    $condition: ModelRatingChangeLogConditionInput
  ) {
    updateRatingChangeLog(input: $input, condition: $condition) {
      id
      matchId
      match {
        id
        playerOneId
        playerOne {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerOneScore
        playerTwoId
        playerTwo {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerTwoScore
        verified
        processed
        created
        updated
        owner
      }
      playerOneId
      playerOne {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerOneInitRating
      playerOneNewRating
      playerTwoId
      playerTwo {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerTwoInitRating
      playerTwoNewRating
      created
      updated
    }
  }
`;
export const deleteRatingChangeLog = /* GraphQL */ `
  mutation DeleteRatingChangeLog(
    $input: DeleteRatingChangeLogInput!
    $condition: ModelRatingChangeLogConditionInput
  ) {
    deleteRatingChangeLog(input: $input, condition: $condition) {
      id
      matchId
      match {
        id
        playerOneId
        playerOne {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerOneScore
        playerTwoId
        playerTwo {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerTwoScore
        verified
        processed
        created
        updated
        owner
      }
      playerOneId
      playerOne {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerOneInitRating
      playerOneNewRating
      playerTwoId
      playerTwo {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerTwoInitRating
      playerTwoNewRating
      created
      updated
    }
  }
`;
