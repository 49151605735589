/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContent = /* GraphQL */ `
  query GetContent($id: ID!) {
    getContent(id: $id) {
      id
      contentType
      title
      slug
      content
      tags
      metadata
      authorId
      author {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      featuredImage
      images
      created
      updated
    }
  }
`;
export const listContents = /* GraphQL */ `
  query ListContents(
    $id: ID
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        contentType
        title
        slug
        content
        tags
        metadata
        authorId
        author {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        featuredImage
        images
        created
        updated
      }
      nextToken
    }
  }
`;
export const contentByType = /* GraphQL */ `
  query ContentByType(
    $contentType: String
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentByType(
      contentType: $contentType
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        title
        slug
        content
        tags
        metadata
        authorId
        author {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        featuredImage
        images
        created
        updated
      }
      nextToken
    }
  }
`;
export const contentByTypeAndSlug = /* GraphQL */ `
  query ContentByTypeAndSlug(
    $contentType: String
    $slug: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentByTypeAndSlug(
      contentType: $contentType
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        title
        slug
        content
        tags
        metadata
        authorId
        author {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        featuredImage
        images
        created
        updated
      }
      nextToken
    }
  }
`;
export const contentByAuthor = /* GraphQL */ `
  query ContentByAuthor(
    $authorId: ID
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentByAuthor(
      authorId: $authorId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        title
        slug
        content
        tags
        metadata
        authorId
        author {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        featuredImage
        images
        created
        updated
      }
      nextToken
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      name
      avatar
      duelRating
      created
      updated
      owner
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $id: ID
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserProfiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      nextToken
    }
  }
`;
export const getMatch = /* GraphQL */ `
  query GetMatch($id: ID!) {
    getMatch(id: $id) {
      id
      playerOneId
      playerOne {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerOneScore
      playerTwoId
      playerTwo {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerTwoScore
      verified
      processed
      created
      updated
      owner
    }
  }
`;
export const listMatches = /* GraphQL */ `
  query ListMatches(
    $id: ID
    $filter: ModelMatchFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMatches(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        playerOneId
        playerOne {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerOneScore
        playerTwoId
        playerTwo {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerTwoScore
        verified
        processed
        created
        updated
        owner
      }
      nextToken
    }
  }
`;
export const matchesByPlayerOne = /* GraphQL */ `
  query MatchesByPlayerOne(
    $playerOneId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    matchesByPlayerOne(
      playerOneId: $playerOneId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        playerOneId
        playerOne {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerOneScore
        playerTwoId
        playerTwo {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerTwoScore
        verified
        processed
        created
        updated
        owner
      }
      nextToken
    }
  }
`;
export const matchesByPlayerTwo = /* GraphQL */ `
  query MatchesByPlayerTwo(
    $playerTwoId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    matchesByPlayerTwo(
      playerTwoId: $playerTwoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        playerOneId
        playerOne {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerOneScore
        playerTwoId
        playerTwo {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerTwoScore
        verified
        processed
        created
        updated
        owner
      }
      nextToken
    }
  }
`;
export const getRatingChangeLog = /* GraphQL */ `
  query GetRatingChangeLog($id: ID!) {
    getRatingChangeLog(id: $id) {
      id
      matchId
      match {
        id
        playerOneId
        playerOne {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerOneScore
        playerTwoId
        playerTwo {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerTwoScore
        verified
        processed
        created
        updated
        owner
      }
      playerOneId
      playerOne {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerOneInitRating
      playerOneNewRating
      playerTwoId
      playerTwo {
        id
        name
        avatar
        duelRating
        created
        updated
        owner
      }
      playerTwoInitRating
      playerTwoNewRating
      created
      updated
    }
  }
`;
export const listRatingChangeLogs = /* GraphQL */ `
  query ListRatingChangeLogs(
    $id: ID
    $filter: ModelRatingChangeLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRatingChangeLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        matchId
        match {
          id
          playerOneId
          playerOneScore
          playerTwoId
          playerTwoScore
          verified
          processed
          created
          updated
          owner
        }
        playerOneId
        playerOne {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerOneInitRating
        playerOneNewRating
        playerTwoId
        playerTwo {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerTwoInitRating
        playerTwoNewRating
        created
        updated
      }
      nextToken
    }
  }
`;
export const ratingChangesByMatch = /* GraphQL */ `
  query RatingChangesByMatch(
    $matchId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRatingChangeLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ratingChangesByMatch(
      matchId: $matchId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        matchId
        match {
          id
          playerOneId
          playerOneScore
          playerTwoId
          playerTwoScore
          verified
          processed
          created
          updated
          owner
        }
        playerOneId
        playerOne {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerOneInitRating
        playerOneNewRating
        playerTwoId
        playerTwo {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerTwoInitRating
        playerTwoNewRating
        created
        updated
      }
      nextToken
    }
  }
`;
export const ratingChangesByPlayerOne = /* GraphQL */ `
  query RatingChangesByPlayerOne(
    $playerOneId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRatingChangeLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ratingChangesByPlayerOne(
      playerOneId: $playerOneId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        matchId
        match {
          id
          playerOneId
          playerOneScore
          playerTwoId
          playerTwoScore
          verified
          processed
          created
          updated
          owner
        }
        playerOneId
        playerOne {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerOneInitRating
        playerOneNewRating
        playerTwoId
        playerTwo {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerTwoInitRating
        playerTwoNewRating
        created
        updated
      }
      nextToken
    }
  }
`;
export const ratingChangesByPlayerTwo = /* GraphQL */ `
  query RatingChangesByPlayerTwo(
    $playerTwoId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRatingChangeLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ratingChangesByPlayerTwo(
      playerTwoId: $playerTwoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        matchId
        match {
          id
          playerOneId
          playerOneScore
          playerTwoId
          playerTwoScore
          verified
          processed
          created
          updated
          owner
        }
        playerOneId
        playerOne {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerOneInitRating
        playerOneNewRating
        playerTwoId
        playerTwo {
          id
          name
          avatar
          duelRating
          created
          updated
          owner
        }
        playerTwoInitRating
        playerTwoNewRating
        created
        updated
      }
      nextToken
    }
  }
`;
