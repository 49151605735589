import React, { Fragment } from "react";
import PropTypes from "prop-types";

const Footer = ({ data }) => {
    return (
        <Fragment>
            <footer>
                <div className="footer-bottom bg-secondary-70 mt-8">
                    <div className="container">
                        <div className=" flex flex-col md:flex-row md:justify-between items-center py-6">
                            <div className="">
                                &copy; {new Date().getFullYear()} Chivalry Duel Masters
                            </div>
                            <div className="footer-copyright-right">
                                <div className="flex">
                                    <a
                                        href="#"
                                        className="text-white hover:text-primary"
                                    >
                                        Privacy Policy{" "}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
};
Footer.propTypes = {
    data: PropTypes.shape({
        footer: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            })
        ),
    }),
};
export default Footer;
