import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const Logo = () => {
    return (
        <Link to="/">
            <StaticImage src="../../assets/images/duelo-logo.png" alt="Duel Masters" />
        </Link>
    );
};
export default Logo;
