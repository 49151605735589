import React from "react";
import PropTypes from "prop-types";
import "@assets/css/icofont.min.css";
import "@assets/css/modal-video.min.css";
import Header from "./header";
import Footer from "./footer";
import BodyImage from "../data/images/others/body-bg.webp";
import { useSelector, useDispatch } from "react-redux";
import { Auth, API } from "aws-amplify";
import { setUser } from "../state/userSlice.js";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { getUserProfile } from "../graphql/queries";
import { createUserProfile } from "../graphql/mutations";

const Layout = ({ data, children }) => {
    const user = useSelector((state) => state.user.value);
    const dispatch = useDispatch();
    React.useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((userObj) => {
                API.graphql({
                    query: getUserProfile,
                    variables: { id: userObj.username },
                })
                    .then((result) => {
                        if (result.data.getUserProfile === null) {
                            API.graphql({
                                query: createUserProfile,
                                variables: {
                                    input: {
                                        id: userObj.username,
                                        name: userObj.attributes.email,
                                        owner: userObj.username,
                                    },
                                },
                            })
                                .then((result2) => {
                                    if (!result2.data.createUserProfile?.id) {
                                        console.log(
                                            "Failed to create userObj profile!"
                                        );
                                    } else {
                                        dispatch(
                                            setUser({
                                                id: userObj.username,
                                                email: userObj.attributes.email,
                                                profile:
                                                    result2.data
                                                        .createUserProfile,
                                            })
                                        );
                                    }
                                })
                                .catch((err) => console.log(err));
                        } else {
                            dispatch(
                                setUser({
                                    id: userObj.username,
                                    email: userObj.attributes.email,
                                    profile: result.data.getUserProfile,
                                })
                            );
                        }
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => {});
    }, []);
    return (
        <div
            className="wrapper"
            style={{
                backgroundImage: `url(${BodyImage})`,
            }}
        >
            <Header data={data} />
            {children}
            <Footer data={data} />
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    data: PropTypes.shape({}),
};

export default Layout;
